import { motion } from "framer-motion";
import { NavigationBar } from "../components/components";
import "../styles/VerifyEmail.css"; // Import CSS file for styling

const childVariants = {
  initial: {
    opacity: 0,
    y: "50px",
  },
  final: {
    opacity: 1,
    y: "0px",
    transition: {
      duration: 0.5,
    },
  },
};

const VerifyEmail = ({ error }) => {
  return (
    <motion.div variants={childVariants} initial="initial" animate="final">
      <NavigationBar color={false} />

      <div className="verify-email">
        {!error ? (
          <>
            <h1 style={{ fontSize: "6vw" }}>Email Verified</h1>
            <h2 style={{ fontSize: "4vw" }}>Log in to One Date</h2>
          </>
        ) : (
          <>
            <h1 style={{ fontSize: "6vw" }}>Try verifying your email again</h1>
            <h2 style={{ fontSize: "4vw" }}>
              Your request to verify your email has expired
              <br />
              or the link has already been used
            </h2>
          </>
        )}
      </div>
    </motion.div>
  );
};

export default VerifyEmail;
