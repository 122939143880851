import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../assets/images/logo.png";

import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/NavigationBar.css"; // Import CSS file for styling

const NavigationBar = ({ color }) => {
  const colorRef = useRef(color); // Store the mutable value of color
  const [hover0, setHover0] = useState(false);
  const [hover1, setHover1] = useState(false);
  const [hover2, setHover2] = useState(false);
  const [hover3, setHover3] = useState(false);
  const [toggleRef, inView] = useInView({ triggerOnce: true });
  const navigate = useNavigate(); // Hook for programmatic navigation
  const location = useLocation(); // Hook to get the current location
  const { state } = useLocation();
  const { targetId } = state || {};

  // Update colorRef.current when inView changes
  useEffect(() => {
    colorRef.current = inView;
  }, [inView]);

  const handleBrandClick = () => {
    if (window.location.pathname === "/") {
      const homeSection = document.getElementById("One-Date");
      if (homeSection) {
        homeSection.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      navigate("/"); // Navigate to root path
    }
  };

  useEffect(() => {
    const el = document.getElementById(targetId);
    if (el) {
      el.scrollIntoView();
    }
  }, [targetId]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top on route change
  }, [location.pathname]);

  return (
    <Navbar expand="lg" variant={color ? "dark" : "light"} className="navbar">
      <div className="container-fluid">
        <Navbar.Brand
          style={{ cursor: "pointer" }}
          onClick={handleBrandClick}
          className="brand-logo"
        >
          <img
            src={logo}
            width="auto"
            height="80vh"
            className="d-inline-block align-top"
            alt="One Date logo"
            style={{ filter: color ? "invert(1)" : "none" }}
          />
        </Navbar.Brand>

        <Navbar.Toggle ref={toggleRef} aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link
              as={Link}
              style={{
                textDecoration: hover0 ? "underline" : "none",
                color: color ? "white" : "black",
              }}
              onMouseEnter={() => {
                setHover0(true);
              }}
              onMouseLeave={() => {
                setHover0(false);
              }}
              to="/vibecheck"
            >
              VibeCheck™
            </Nav.Link>
            <Nav.Link
              as={Link}
              style={{
                textDecoration: hover1 ? "underline" : "none",
                color: color ? "white" : "black",
              }}
              onMouseEnter={() => {
                setHover1(true);
              }}
              onMouseLeave={() => {
                setHover1(false);
              }}
              to="/vibeconnect"
            >
              VibeConnect™
            </Nav.Link>
            <Nav.Link
              as={Link}
              style={{
                textDecoration: hover2 ? "underline" : "none",
                color: color ? "white" : "black",
              }}
              onMouseEnter={() => {
                setHover2(true);
              }}
              onMouseLeave={() => {
                setHover2(false);
              }}
              to="/stay-safe"
            >
              Stay Safe
            </Nav.Link>
            <Nav.Link
              as={Link}
              style={{
                textDecoration: hover3 ? "underline" : "none",
                color: color ? "white" : "black",
              }}
              onMouseEnter={() => {
                setHover3(true);
              }}
              onMouseLeave={() => {
                setHover3(false);
              }}
              to="/about-us"
            >
              About Us
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

export default NavigationBar;
