import { useEffect } from "react";
import gsap from "gsap";
import { motion } from "framer-motion";
import "../styles/Preloader.css"; // Import CSS file for styling

// Declare a timeline for the preloader animation
const tl = gsap.timeline();

const preloaderAnimation = () => {
  tl
    // Hide body overflow
    .to("body", {
      duration: 1.2,
      css: { overflowY: "hidden" },
      ease: "power3.inOut",
    })

    // Animate preloader height to 0vh (hide)
    .to(".preloader", {
      duration: 1.2,
      height: "0vh",
      y: "-100vh",
      ease: "Power3.easeOut",
    })
    .to(".preloader", {
      duration: 0,
      css: { display: "none" },
      onComplete: () => {
        // Change html overflow to scroll
        document.documentElement.style.overflow = "scroll";
      },
    });
};

const svgVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { duration: 0.1 },
  },
};

const staggerVariants = {
  hidden: {
    opacity: 0,
    pathLength: 0,
  },
  visible: (i) => {
    const delay = 0.1 + i * 0.1;
    return {
      opacity: 1,
      pathLength: 1,
      transition: {
        pathLength: { delay, type: "spring", duration: 0.1, bounce: 0 },
        opacity: { delay, duration: 0.1 },
      },
    };
  },
};

const paths = [
  "M 395.5,1.5 C 415.772,-0.456624 428.939,8.21004 435,27.5C 437.388,48.4293 428.555,61.9293 408.5,68C 381.818,68.9847 368.652,56.1513 369,29.5C 372.238,14.7646 381.071,5.43128 395.5,1.5 Z",
  "M 187.5,34.5 C 281.02,31.9849 348.52,72.3182 390,155.5C 418.107,225.738 411.44,292.405 370,355.5C 320.489,420.154 254.989,447.987 173.5,439C 89.6253,423.124 34.4587,374.624 8,293.5C -11.9977,209.508 9.8356,138.675 73.5,81C 106.867,54.1503 144.867,38.6503 187.5,34.5 Z M 190.5,114.5 C 243.841,112.081 284.341,133.414 312,178.5C 331.9,219.503 331.233,260.17 310,300.5C 278.102,348.524 233.602,367.691 176.5,358C 130.512,346.179 100.178,317.679 85.5,272.5C 72.9863,221.549 85.9863,178.382 124.5,143C 144.028,127.735 166.028,118.235 190.5,114.5 Z",
  "M 641.5,83.5 C 696.529,79.9351 739.696,100.602 771,145.5C 798.649,191.161 798.316,236.494 770,281.5C 766.205,284.803 762.038,285.303 757.5,283C 740.667,270.833 723.833,258.667 707,246.5C 705.543,243.634 705.21,240.634 706,237.5C 720.691,214.742 719.691,192.742 703,171.5C 683.896,153.597 662.73,150.764 639.5,163C 633.742,166.754 628.575,171.254 624,176.5C 575.333,245.167 526.667,313.833 478,382.5C 456.993,410.51 429.159,421.01 394.5,414C 384.91,411.643 376.243,407.476 368.5,401.5C 434.842,307.481 501.676,213.815 569,120.5C 588.904,99.798 613.071,87.4646 641.5,83.5 Z",
  "M 709.5,267.5 C 712.996,268.748 715.996,270.748 718.5,273.5C 714.833,278.833 711.167,284.167 707.5,289.5C 708.888,292.079 710.388,292.079 712,289.5C 714.29,285.381 717.124,281.714 720.5,278.5C 723.135,279.811 725.469,281.478 727.5,283.5C 724.5,288.167 721.5,292.833 718.5,297.5C 719.62,299.596 721.12,299.93 723,298.5C 726.422,293.322 730.089,288.322 734,283.5C 736.669,285.34 739.169,287.34 741.5,289.5C 735.49,298.853 728.99,307.853 722,316.5C 711.212,309.694 701.046,302.194 691.5,294C 697.732,285.278 703.732,276.445 709.5,267.5 Z",
  "M 687.5,298.5 C 690.09,299.128 692.423,300.295 694.5,302C 695.574,302.739 696.241,303.739 696.5,305C 694.907,308.262 692.907,311.262 690.5,314C 697.975,319.308 705.308,324.808 712.5,330.5C 710.234,334.043 707.734,337.376 705,340.5C 697.431,335.63 689.931,330.63 682.5,325.5C 680.408,328.681 678.075,331.681 675.5,334.5C 672.864,333.029 670.364,331.363 668,329.5C 667.333,328.5 667.333,327.5 668,326.5C 674.766,317.283 681.266,307.95 687.5,298.5 Z",
  "M 657.5,340.5 C 671.074,343.638 684.408,347.471 697.5,352C 694.92,355.158 692.587,358.492 690.5,362C 688.568,362.138 686.734,361.638 685,360.5C 682.132,363.736 679.632,367.236 677.5,371C 679.797,372.263 680.63,374.097 680,376.5C 677.908,379.681 675.575,382.681 673,385.5C 665.421,375.342 658.088,365.009 651,354.5C 650.333,353.167 650.333,351.833 651,350.5C 653.81,347.536 655.976,344.202 657.5,340.5 Z M 665.5,355.5 C 665.56,354.957 665.893,354.624 666.5,354.5C 669.539,355.278 672.206,356.612 674.5,358.5C 673.5,359.833 672.5,361.167 671.5,362.5C 669.448,360.122 667.448,357.789 665.5,355.5 Z",
  "M 641.5,371.5 C 652.852,371.753 660.685,377.086 665,387.5C 665.589,392.481 664.589,397.148 662,401.5C 659,405.833 656,410.167 653,414.5C 652.261,415.574 651.261,416.241 650,416.5C 639.587,409.708 629.421,402.541 619.5,395C 623.457,389.587 627.29,384.087 631,378.5C 634.154,375.488 637.654,373.155 641.5,371.5 Z M 639.5,384.5 C 644.503,384.085 648.67,385.752 652,389.5C 654.295,393.692 653.628,397.359 650,400.5C 645.167,397 640.333,393.5 635.5,390C 636.665,388.008 637.998,386.175 639.5,384.5 Z",
  "M 604.5,414.5 C 608.031,415.697 611.031,417.697 613.5,420.5C 609.833,425.833 606.167,431.167 602.5,436.5C 604.59,439.009 606.423,438.675 608,435.5C 609.796,432.073 611.963,428.906 614.5,426C 615.5,425.333 616.5,425.333 617.5,426C 619.466,427.46 621.132,429.127 622.5,431C 619.682,435.156 616.848,439.323 614,443.5C 614.845,446.731 616.512,447.064 619,444.5C 622.333,439.833 625.667,435.167 629,430.5C 631.669,432.34 634.169,434.34 636.5,436.5C 630.556,445.777 624.222,454.777 617.5,463.5C 606.991,456.412 596.658,449.079 586.5,441.5C 592.769,432.615 598.769,423.615 604.5,414.5 Z",
  "M 581.5,447.5 C 592.546,454.366 603.213,461.866 613.5,470C 611.257,472.488 609.257,475.154 607.5,478C 599.843,479.066 592.176,480.066 584.5,481C 588.667,483.833 592.833,486.667 597,489.5C 596.369,493.752 594.369,497.418 591,500.5C 580.536,493.357 570.369,485.857 560.5,478C 562.086,474.415 564.419,471.415 567.5,469C 574.843,468.444 582.177,467.777 589.5,467C 584.616,463.732 579.949,460.232 575.5,456.5C 577.526,453.482 579.526,450.482 581.5,447.5 Z",
  "M 555.5,489.5 C 567.98,488.649 576.48,493.983 581,505.5C 581.626,517.537 576.459,526.037 565.5,531C 553.363,532.524 544.863,527.691 540,516.5C 539.066,503.947 544.233,494.947 555.5,489.5 Z M 556.5,501.5 C 565.218,502.373 569.385,507.04 569,515.5C 567.093,519.01 564.26,520.176 560.5,519C 556.028,517.028 552.695,513.862 550.5,509.5C 551.406,505.938 553.406,503.272 556.5,501.5 Z",
];

const Preloader = () => {
  // const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    preloaderAnimation();
  }, []);

  /*
  useEffect(() => {
    const interval = setInterval(() => {
      setIsVisible((prevVisible) => !prevVisible);
    }, 1100);

    return () => clearInterval(interval);
  }, []);
  */

  return (
    <div className="preloader">
      <motion.svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        style={{
          shapeRendering: "geometricPrecision",
          textRendering: "geometricPrecision",
          imageRendering: "optimizeQuality",
          fillRule: "evenodd",
          clipRule: "evenodd",
          position: "fixed",
          top: "55.5%",
          left: "59%",
          height: "100vh",
          width: "100vw",
          transform: "scale(2)",
        }}
        xmlnsXlink="http://www.w3.org/1999/xlink"
        variants={svgVariants}
        initial="hidden"
        animate="visible"
      >
        {paths.map((pathData, index) => (
          <motion.path
            key={index} // Important for animation ordering
            d={pathData}
            variants={staggerVariants}
            initial="hidden"
            animate="visible"
            // animate={isVisible ? "visible" : "hidden"} // Toggle between hidden and visible states
            custom={index} // Add a custom prop for stagger effect
          />
        ))}
      </motion.svg>
    </div>
  );
};

export default Preloader;
