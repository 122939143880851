import { useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  verifyPasswordResetCode,
  applyActionCode,
} from "firebase/auth";
import { ResetPassword, VerifyEmail, Error } from "../pages/pages";

const firebaseConfig = {
  // Replace with actual Firebase configuration
  apiKey: "AIzaSyDootqk-_Esa4DECwUmaVwHp0cgROVEurY",
};

function EmailActionHandlers() {
  const [auth, setAuth] = useState(null);
  const [mode, setMode] = useState(null);
  const [actionCode, setActionCode] = useState(null);
  const [lang, setLang] = useState("en");
  const [error, setError] = useState(null);

  useEffect(() => {
    const app = initializeApp(firebaseConfig);
    setAuth(getAuth(app));

    const urlParams = new URLSearchParams(window.location.search);
    setMode(urlParams.get("mode"));
    setActionCode(urlParams.get("oobCode"));
    setLang(urlParams.get("lang") || "en");
  }, []);

  useEffect(() => {
    if (auth && mode && actionCode) {
      const handleResetPassword = async (actionCode, lang) => {
        try {
          await verifyPasswordResetCode(auth, actionCode);
          setError(false);
        } catch {
          setError(true);
        }
      };

      const handleVerifyEmail = async (actionCode, lang) => {
        try {
          await applyActionCode(auth, actionCode);
          setError(false);
        } catch {
          setError(true);
        }
      };

      switch (mode) {
        case "resetPassword":
          handleResetPassword(actionCode, lang);
          break;
        case "verifyEmail":
          handleVerifyEmail(actionCode, lang);
          break;
        default:
          console.error("Invalid mode");
      }
    }
  }, [auth, mode, actionCode, lang]);

  return (
    <>
      {mode === "resetPassword" && (
        <ResetPassword auth={auth} actionCode={actionCode} error={error} />
      )}
      {mode === "verifyEmail" && <VerifyEmail error={error} />}
      {(!mode || mode === "invalid") && <Error />}
    </>
  );
}

export default EmailActionHandlers;
