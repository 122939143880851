import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

const messageVariantFromMe = {
  visible: { opacity: 1, x: 0, transition: { duration: 1 } },
  hidden: { opacity: 0, x: 100 },
};

const messageVariantToMe = {
  visible: { opacity: 1, x: 0, transition: { duration: 2 } },
  hidden: { opacity: 0, x: -100 },
};

const Message = ({ message, isFromMe }) => {
  const control = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: true });

  useEffect(() => {
    if (inView) {
      control.start("visible");
    }
  }, [control, inView]);

  const messageVariant = isFromMe ? messageVariantFromMe : messageVariantToMe;

  return (
    <motion.div
      className={isFromMe ? "from-me-container" : "to-me-container"}
      ref={ref}
      variants={messageVariant}
      initial="hidden"
      animate={control}
    >
      <p className={isFromMe ? "from-me" : "to-me"}>{message}</p>
    </motion.div>
  );
};

export default Message;
