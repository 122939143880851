import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Slide } from "../components";
import "../../styles/home/Introduction.css"; // Import CSS file for styling

const content = [
  "Tired of endless matching?",
  "One Date prioritizes real dates, not profiles",
  "Choose your activity, find your match",
  "It's a win-win - you spark new connections and/or explore exciting new places!",
];

const Introduction = ({ style }) => {
  const [periods, setPeriods] = useState(".");

  useEffect(() => {
    const interval = setInterval(() => {
      setPeriods((prevPeriods) => {
        switch (prevPeriods) {
          case "":
            return ".";
          case ".":
            return "..";
          case "..":
            return "...";
          case "...":
            return "";
          default:
            return "";
        }
      });
    }, 1000); // Change the interval duration as needed

    return () => clearInterval(interval);
  }, []);

  return (
    <div style={style}>
      <Slide
        isLeft={true}
        content={<h1 className="content-left">{content[0]}</h1>}
      />
      <Slide
        isLeft={false}
        content={
          <h1 className="content-right" style={{ margin: "-4vh 0" }}>
            {content[1]} <br /> {content[2]}
          </h1>
        }
      />
      <Slide
        isLeft={true}
        content={<h1 className="content-left">{content[3]}</h1>}
      />

      <h1 className="center-text" style={{ fontSize: "18vh" }}>
        NOW
        <motion.span
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
          style={{ display: "inline-block", marginLeft: "4px" }}
        >
          {periods}
        </motion.span>
      </h1>
    </div>
  );
};

export default Introduction;
