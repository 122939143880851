import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

const Slide = ({ isLeft, content }) => {
  const contentControls = useAnimation();
  const [contentRef, inView] = useInView({ triggerOnce: true });

  useEffect(() => {
    if (inView) {
      contentControls.start("visible");
    }
  }, [contentControls, inView]);

  const contentVariant = {
    hidden: isLeft ? { opacity: 0, x: -100 } : { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
  };

  return (
    <motion.div
      ref={contentRef} // Use the same ref for content and height
      className="content"
      variants={contentVariant}
      initial="hidden"
      animate={contentControls}
    >
      {content}
    </motion.div>
  );
};

export default Slide;
