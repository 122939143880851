import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { NavigationBar } from "../components/components";
import "../styles/common.css"; // Import CSS file for styling

const childVariants = {
  initial: {
    opacity: 0,
    y: "50px",
  },
  final: {
    opacity: 1,
    y: "0px",
    transition: {
      duration: 0.5,
    },
  },
};

const IntellectualProperty = () => {
  const [navHeight, setNavHeight] = useState(0); // State to store navigation bar height

  // Measure navigation bar height
  useEffect(() => {
    const navbar = document.querySelector(".navbar");
    if (navbar) {
      const height = navbar.offsetHeight;
      setNavHeight(height);
    }
  }, []);

  return (
    <motion.div variants={childVariants} initial="initial" animate="final">
      <NavigationBar color={false} />

      <div className="common" style={{ paddingTop: navHeight }}>
        <h1>One Date Intellectual Property</h1>
        <caption>Effective Date: 06 April 2024</caption>
        <p>
          One Date ("One Date," "we," "us," or "our") respects the intellectual
          property rights of others and expects our users to do the same. This
          Intellectual Property Notice ("Notice") outlines the intellectual
          property rights associated with the One Date mobile application (the
          "App").
        </p>
        <h2>1. Trademarks</h2>
        <ul>
          <li>
            One Date and the One Date Logo (collectively, the "Trademarks") are
            trademarks or service marks of One Date. These Trademarks may not be
            used in connection with any product or service that is not ours, or
            in any manner that is likely to cause confusion among customers, or
            in any way that disparages or discredits One Date.
          </li>
        </ul>
        <h2>2. Copyrights</h2>
        <p>
          All content on the App, including but not limited to text, graphics,
          logos, icons, images, and software, is protected by copyright. This
          content is the property of One Date or its licensors and may not be
          reproduced, distributed, modified, transmitted, or displayed without
          our express written permission.
        </p>
        <h2>3. VibeCheck™</h2>
        <ul>
          <li>
            VibeCheck™ is a trademark of One Date and may not be used by any
            third party without our express written permission. VibeCheck™
            refers to a specific feature or functionality within the One Date
            App.
          </li>
        </ul>
        <h2>4. Third-Party Content</h2>
        <p>
          The App may contain content owned by third parties ("Third-Party
          Content"). We do not own or control the Third-Party Content, and we
          are not responsible for its accuracy or legality. Your use of the
          Third-Party Content is subject to the terms and conditions of the
          third party that owns the content.
        </p>
        <h2>5. User Content</h2>
        <p>
          You retain all ownership rights to your User Content. However, by
          submitting User Content to the App, you grant One Date a
          non-exclusive, royalty-free, worldwide license to use, modify,
          reproduce, distribute, display, and translate your User Content in
          connection with the App and our services.
        </p>
        <h2>6. DMCA Notice</h2>
        <caption>
          If you believe that your copyrighted work has been copied in a way
          that constitutes copyright infringement, please provide us with a
          written notice ("DMCA Notice") containing the following information:
        </caption>
        <ul>
          <li>
            An electronic or physical signature of the person authorized to act
            on behalf of the owner of the copyright interest;
          </li>
          <li>
            A description of the copyrighted work that you claim has been
            infringed;
          </li>
          <li>
            A description of where the material that you claim is infringing is
            located on the App;
          </li>
          <li>Your address, telephone number, and email address;</li>
          <li>
            A statement by you that you have a good faith belief that the
            disputed use is not authorized by the copyright owner, its agent, or
            the law; and
          </li>
          <li>
            A statement by you, made under penalty of perjury, that the
            information in the DMCA Notice is accurate and that you are the
            copyright owner or authorized to act on the copyright owner's
            behalf.
          </li>
          <li>Our designated agent for receiving DMCA Notices is:</li>
          <caption>
            One Date
            <br />
            notice@onedate.app
          </caption>
        </ul>
        <h2>7. Termination</h2>
        <p>
          We may terminate your access to the App if you infringe or violate the
          intellectual property rights of One Date or others.
        </p>
        <h2>8. Changes to this Notice</h2>
        <p>
          We may update this Notice at any time. We will notify you of any
          changes by posting the new Notice on the App. You are advised to
          review the Notice periodically for any changes. Your continued use of
          the App after the revised Notice is posted constitutes your acceptance
          of the revised Notice.
        </p>
        <h2>9. Contact Us</h2>
        <p>
          If you have any questions about this Notice, please contact us at{" "}
          <a style={{ color: "inherit" }} href="mailto:notice@onedate.app">
            notice@onedate.app
          </a>
          .
        </p>
      </div>
    </motion.div>
  );
};

export default IntellectualProperty;
