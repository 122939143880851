import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { NavigationBar } from "../components/components";
import "../styles/common.css"; // Import CSS file for styling

const childVariants = {
  initial: {
    opacity: 0,
    y: "50px",
  },
  final: {
    opacity: 1,
    y: "0px",
    transition: {
      duration: 0.5,
    },
  },
};

const FAQs = () => {
  const [navHeight, setNavHeight] = useState(0); // State to store navigation bar height

  // Measure navigation bar height
  useEffect(() => {
    const navbar = document.querySelector(".navbar");
    if (navbar) {
      const height = navbar.offsetHeight;
      setNavHeight(height);
    }
  }, []);

  return (
    <motion.div variants={childVariants} initial="initial" animate="final">
      <NavigationBar color={false} />

      <div className="common" style={{ paddingTop: navHeight }}>
        <h1>One Date FAQs</h1>
        <h2>Matching & VibeCheck</h2>
        <ul>
          <li>
            <span style={{ fontWeight: "bolder" }}>
              How is One Date different from other dating apps?
            </span>
            <br />
            One Date focuses on shared activities for the first date, ensuring
            compatibility beyond just profiles.
          </li>
          <li>
            <span style={{ fontWeight: "bolder" }}>What are VibeChecks?</span>
            <br />
            VibeChecks are curated date ideas you choose from, allowing matches
            who share your interests to connect.
          </li>
          <li>
            <span style={{ fontWeight: "bolder" }}>
              Can I still chat with matches before the date?
            </span>
            <br />
            Absolutely! Chatting is encouraged to solidify details and get to
            know each other better.
          </li>
        </ul>
        <h2>Date Ideas & Activities</h2>
        <ul>
          <li>
            <span style={{ fontWeight: "bolder" }}>
              What kind of date ideas are available?
            </span>
            <br />
            One Date offers a variety of options, from dining and entertainment
            to social experiences.
          </li>
          <li>
            <span style={{ fontWeight: "bolder" }}>
              Can I suggest my own date ideas?
            </span>
            <br />
            Yes! We love fresh ideas. Share them through our feedback email at{" "}
            <a style={{ color: "inherit" }} href="mailto:contact@onedate.app">
              contact@onedate.app
            </a>
            .
          </li>
          <li>
            <span style={{ fontWeight: "bolder" }}>
              What happens if I don't see any interesting date ideas?
            </span>
            <br />
            New options are added regularly. You can also browse matches based
            on general profiles.
          </li>
        </ul>
        <h2>Local Businesses & Community</h2>
        <ul>
          <li>
            <span style={{ fontWeight: "bolder" }}>
              How does One Date support local businesses?
            </span>
            <br />
            We partner with venues to showcase their offerings, bringing new
            customers through VibeChecked dates.
          </li>
          <li>
            <span style={{ fontWeight: "bolder" }}>
              What types of businesses are included?
            </span>
            <br />
            One Date features a wide range of establishments, including
            restaurants, bars, activity centers, and more.
          </li>
          <li>
            <span style={{ fontWeight: "bolder" }}>
              Are there benefits for businesses to partner with One Date?
            </span>
            <br />
            Absolutely! Businesses gain exposure to a targeted audience and
            increased foot traffic.
          </li>
        </ul>
        <h2>General</h2>
        <ul>
          <li>
            <span style={{ fontWeight: "bolder" }}>
              Is One Date free to use?
            </span>
            <br />
            We offer both free and premium features.
          </li>
          <li>
            <span style={{ fontWeight: "bolder" }}>
              How do I get started with One Date?
            </span>
            <br />
            Download the app, create a profile, and select your preferred date
            ideas!
          </li>
          <li>
            <span style={{ fontWeight: "bolder" }}>
              Is One Date safe to use?
            </span>
            <br />
            Your safety is our priority. You can block users who exhibit
            inappropriate behavior. We also encourage responsible dating
            practices within the app.
          </li>
        </ul>
      </div>
    </motion.div>
  );
};

export default FAQs;
