import { useState } from "react";
import { confirmPasswordReset } from "firebase/auth";
import { motion } from "framer-motion";
import {
  NavigationBar,
  NeumorphismFlatResetInput,
  NeumorphismFlatResetButton,
} from "../components/components";
import "../styles/ResetPassword.css"; // Import CSS file for styling

const childVariants = {
  initial: {
    opacity: 0,
    y: "50px",
  },
  final: {
    opacity: 1,
    y: "0px",
    transition: {
      duration: 0.5,
    },
  },
};

const ResetPassword = ({ auth, actionCode, error }) => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [passwordError, setPasswordError] = useState(""); // Separate state for detailed error messages
  const [passwordResetSuccessful, setPasswordResetSuccessful] = useState("");

  const _passwordRegExp =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()_+{}|:"<>?~-]).{12,}$/;

  const handleResetPassword = async () => {
    setPasswordError(""); // Clear previous errors

    if (newPassword !== confirmNewPassword) {
      setPasswordError("Passwords don't match");
      return;
    }

    // Check if passwords meet the regex pattern
    if (!_passwordRegExp.test(newPassword)) {
      setPasswordError(
        <>
          <div>• Password must be at least 12 characters long</div>
          <div>• Password must contain at least one uppercase letter</div>
          <div>• Password must contain at least one lowercase letter</div>
          <div>• Password must contain at least one number</div>
          <div>• Password must contain at least one special character</div>
        </>
      );
      return;
    }

    await confirmPasswordReset(auth, actionCode, newPassword);

    setPasswordResetSuccessful("Password Reset Successful");
  };

  return (
    <motion.div variants={childVariants} initial="initial" animate="final">
      <NavigationBar color={false} />

      <div className="reset-password">
        {!error ? (
          <>
            <h1
              style={{
                fontSize: "6vw",
                alignSelf: "flex-start",
                marginLeft: "24px",
              }}
            >
              Reset Password
            </h1>
            <NeumorphismFlatResetInput
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="New Password"
            />
            <NeumorphismFlatResetInput
              type="password"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              placeholder="Confirm New Password"
            />
            {passwordResetSuccessful === "Password Reset Successful" ? (
              <></>
            ) : (
              <NeumorphismFlatResetButton
                onClick={handleResetPassword}
                title="Reset Password"
              />
            )}
            {passwordResetSuccessful && (
              <motion.div
                variants={childVariants}
                initial="initial"
                animate="final"
              >
                <h1
                  style={{ fontSize: "3vw", color: "green", marginTop: "1vw" }}
                >
                  {passwordResetSuccessful}
                </h1>
              </motion.div>
            )}
            {passwordError && (
              <motion.div
                variants={childVariants}
                initial="initial"
                animate="final"
              >
                <h1
                  style={{
                    fontSize: "3vw",
                    color: "red",
                    textAlign: "start",
                    alignSelf: "flex-start",
                    marginLeft: "24px",
                  }}
                >
                  {passwordError}
                </h1>
              </motion.div>
            )}
          </>
        ) : (
          <>
            <h1 style={{ fontSize: "6vw" }}>
              Try resetting your password again
            </h1>
            <h2 style={{ fontSize: "4vw" }}>
              Your request to reset your password has expired
              <br />
              or the link has already been used
            </h2>
          </>
        )}
      </div>
    </motion.div>
  );
};

export default ResetPassword;
