import { motion } from "framer-motion";
import { NavigationBar } from "../components/components";
import "../styles/Error.css"; // Import CSS file for styling

const childVariants = {
  initial: {
    opacity: 0,
    y: "50px",
  },
  final: {
    opacity: 1,
    y: "0px",
    transition: {
      duration: 0.5,
    },
  },
};

const Error = () => {
  return (
    <motion.div variants={childVariants} initial="initial" animate="final">
      <NavigationBar color={false} />

      <div className="error">
        <h1 style={{ fontSize: "6vw" }}>404</h1>
        <h2 style={{ fontSize: "4vw" }}>Page Not Found</h2>
      </div>
    </motion.div>
  );
};

export default Error;
