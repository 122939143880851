import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { NavigationBar } from "../components/components";
import "../styles/common.css"; // Import CSS file for styling

const childVariants = {
  initial: {
    opacity: 0,
    y: "50px",
  },
  final: {
    opacity: 1,
    y: "0px",
    transition: {
      duration: 0.5,
    },
  },
};

const PrivacyPolicy = () => {
  const [navHeight, setNavHeight] = useState(0); // State to store navigation bar height

  // Measure navigation bar height
  useEffect(() => {
    const navbar = document.querySelector(".navbar");
    if (navbar) {
      const height = navbar.offsetHeight;
      setNavHeight(height);
    }
  }, []);

  return (
    <motion.div variants={childVariants} initial="initial" animate="final">
      <NavigationBar color={false} />

      <div className="common" style={{ paddingTop: navHeight }}>
        <h1>One Date Privacy Policy</h1>
        <caption>Effective Date: 06 April 2024</caption>
        <p>
          One Date ('One Date,' 'we,' 'us,' or 'our') is committed to protecting
          the privacy of our users ('you' or 'your'). This Privacy Policy
          explains what information we collect, how we use it, and your choices
          regarding this information.
        </p>
        <h2>Information We Collect:</h2>
        <caption>
          We collect the following information when you use our app:
        </caption>
        <ul>
          <li>
            <span style={{ fontWeight: "bolder" }}>Account Information:</span>{" "}
            When you create an account, we collect your email address, name, and
            any information you choose to include in your profile.
          </li>
          <li>
            <span style={{ fontWeight: "bolder" }}>Usage Data:</span> We collect
            data about your activity on the app, such as your likes and
            dislikes, and messages you send and receive.
          </li>
        </ul>
        <h2>How We Use Your Information:</h2>
        <caption>
          We use the information we collect for the following purposes:
        </caption>
        <ul>
          <li>To create and manage your account</li>
          <li>To operate and improve the One Date app</li>
          <li>To connect you with other users</li>
          <li>To personalize your experience on the app</li>
          <li>To comply with legal and regulatory requirements</li>
        </ul>
        <h2>Firebase and Your Information:</h2>
        <p>
          One Date uses Firebase, a service provided by Google, as our backend.
          Google collects and stores information in accordance with their own{" "}
          <a
            style={{ color: "inherit" }}
            href="https://firebase.google.com/support/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
          . We recommend you review their policy for more details.
        </p>
        <h2>Sharing Your Information:</h2>
        <p>
          We may share your information with third-party service providers who
          help us operate the app. We will only share your information with
          these providers in accordance with this Privacy Policy and any
          necessary agreements.
          <br />
          <br />
          We will not share your information with any third-party for marketing
          purposes without your consent.
        </p>
        <h2>Your Choices:</h2>
        <caption>You have choices regarding your information:</caption>
        <ul>
          <li>
            You can access and update your profile information at any time.
          </li>
          <li>You can request to delete your account.</li>
        </ul>
        <h2>Security:</h2>
        <p>
          We take reasonable steps to protect your information from unauthorized
          access, disclosure, alteration, or destruction. However, no security
          measures are perfect, and we cannot guarantee the security of your
          information.
        </p>
        <h2>Children's Privacy:</h2>
        <p>
          Our app is not intended for children under the age of 18. We do not
          knowingly collect information from children under 18. If you are a
          parent or guardian and you believe your child has provided us with
          information, please contact us.
        </p>
        <h2>Changes to this Privacy Policy:</h2>
        <p>
          We may update this Privacy Policy from time to time. We encourage you
          to periodically review the Privacy Policy for any changes.
        </p>
        <h2>Contact Us:</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact us
          at{" "}
          <a
            style={{ color: "inherit" }}
            href="mailto:privacypolicy@onedate.app"
          >
            privacy@onedate.app
          </a>
          .
        </p>
      </div>
    </motion.div>
  );
};

export default PrivacyPolicy;
