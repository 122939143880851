import { useEffect, useState } from "react";
import { ParallaxProvider } from "react-scroll-parallax";
import { Banner, Introduction, Why } from "../components/components.js";
import "../styles/home/Home.css"; // Import CSS file for styling 

const Home = () => {
  const [navHeight, setNavHeight] = useState(0); // State to store navigation bar height

  // Measure navigation bar height
  useEffect(() => {
    const navbar = document.querySelector(".navbar");
    if (navbar) {
      const height = navbar.offsetHeight;
      setNavHeight(height);
    }
  }, []);

  return (
    <div className="home">
      <ParallaxProvider>
        <Banner />
        <Introduction style={{ paddingTop: navHeight }} />
        <Why />
      </ParallaxProvider>
    </div>
  );
};

export default Home;
