import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { NavigationBar } from "../components/components";
import "../styles/common.css"; // Import CSS file for styling

const childVariants = {
  initial: {
    opacity: 0,
    y: "50px",
  },
  final: {
    opacity: 1,
    y: "0px",
    transition: {
      duration: 0.5,
    },
  },
};

const StaySafe = () => {
  const [navHeight, setNavHeight] = useState(0); // State to store navigation bar height

  // Measure navigation bar height
  useEffect(() => {
    const navbar = document.querySelector(".navbar");
    if (navbar) {
      const height = navbar.offsetHeight;
      setNavHeight(height);
    }
  }, []);

  return (
    <motion.div variants={childVariants} initial="initial" animate="final">
      <NavigationBar color={false} />

      <div className="common" style={{ paddingTop: navHeight }}>
        <h1>Stay Safe When Meeting Someone In-Person on One Date</h1>
        <p>
          Meeting someone new can be exciting, but it's important to prioritize
          your safety. Here are some tips to help you stay safe when meeting
          someone in person from One Date:
        </p>
        <h2>Before You Meet:</h2>
        <ul>
          <li>
            Get to know them virtually first: Spend some time chatting on the
            app to get a feel for the person. Look for red flags or
            inconsistencies in their stories.
          </li>
          <li>
            Do a Google search: See if any information about them pops up
            online. You can also try searching for their social media profiles
            (if they mentioned them).
          </li>
          <li>
            Meet in a public place: Choose a well-lit and populated location
            like a cafe, restaurant with a patio, or a park during the day.
            Avoid secluded areas or meeting them at their home.
          </li>
          <li>
            Let a friend or family member know: Tell someone you trust where
            you're going, who you're meeting, and when you expect to be back.
            Keep them updated throughout the date.
          </li>
          <li>
            Trust your gut: If you feel uncomfortable at any point, don't
            hesitate to end the date and leave.
          </li>
        </ul>
        <h2>During Your Date:</h2>
        <ul>
          <li>
            Go with your own transportation: This way, you have the freedom to
            leave whenever you want.
          </li>
          <li>
            Bring your phone: Keep your phone charged and easily accessible in
            case you need to call someone or use a safety app.
          </li>
          <li>
            Pay for yourself: Avoid any financial dependence on your date early
            on.
          </li>
          <li>
            Limit your alcohol intake: Stay sober enough to be aware of your
            surroundings and make clear decisions.
          </li>
          <li>
            Don't feel pressured to be intimate: Only progress physically at
            your own pace and comfort level.
          </li>
          <li>
            Be aware of your surroundings: Pay attention to your date's behavior
            and the people around you.
          </li>
        </ul>
        <h2>After Your Date:</h2>
        <ul>
          <li>
            Let your friend or family member know you're safe: Give them a quick
            update that you're back home or finished with your date.
          </li>
          <li>
            Reflect on your experience: Consider if you felt safe and
            comfortable throughout the date. If not, it's okay to block or
            unmatch the person on One Date.
          </li>
        </ul>
        <h2>Additional Safety Measures:</h2>
        <p>
          While not mandatory, here are some additional steps you can take to
          further ensure your safety:
        </p>
        <ul>
          <li>
            Consider a virtual background check service: There are services
            available that can provide more information about someone based on
            their name and other details.
          </li>
          <li>
            Be cautious when meeting someone new: Always prioritize meeting in
            public places and letting a friend or family member know your plans.
          </li>
        </ul>
        <h2>Remember:</h2>
        <p>
          Your safety is paramount. Don't be afraid to assert your boundaries
          and take steps to protect yourself. One Date is committed to creating
          a safe and positive dating experience for everyone. If you encounter
          any inappropriate behavior, report it to the app immediately.
        </p>
      </div>
    </motion.div>
  );
};

export default StaySafe;
